<template>
    <div>
        <b-loading v-model="isLoading" :is-full-page="true" />
        <div v-if="this.keywords.length > 0">
            <ul v-for="url in keywords" :key="url.id">
                <li v-for="keyword in url.keywords" :key="keyword.id">
                    {{ url.url }}
                    &nbsp;<a
                        :href="`/search/keyword?keyword=${encodeKeyword(keyword.keyword)}&lang=${
                            keyword.lang.split('_')[1]
                        }`"
                        target="_blank"
                        style="color: rgb(0, 123, 255)"
                    >
                        {{ keyword.keyword }}
                    </a>
                </li>
            </ul>
        </div>
        <span v-if="!isLoading"> Aucun mot clé trouvé </span>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        title: 'Keywords',
        name: 'GroupSiteKeywords',
        data: () => ({
            isLoading: true
        }),
        computed: {
            ...mapState('sites', ['keywords'])
        },
        methods: {
            ...mapActions('sites', ['getKeywords']),
            encodeKeyword(keyword) {
                return encodeURI(keyword)
            }
        },
        created() {
            this.getKeywords({ siteId: this.$route.query.id }).finally(() => (this.isLoading = false))
        }
    }
</script>
